import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const KampanieProduktowePLAPage = ({ location: { pathname } }) => {
  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, 2550 / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = [
        "Szybkie efekty",
        "Natychmiastowa widoczność",
        "Przejrzyste wyniki",
        "Przyjazny model rozliczeń",
      ];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Kampanie produktowe Product Listing Ads PLA",
        description:
          "Kampanie PLA to skuteczny sposób promowania Twojego sklepu internetowego w wyszukiwarce Google. Kampanie product listening ads są idealnym rozwiązaniem dla e-commerce, które chcą zwiększyć sprzedaż swoich produktów. Dzięki naszej pomocy Twoje produkty zostaną wyróżnione na tle konkurencji i dotrą do odpowiedniej grupy odbiorców. Zwiększ sprzedaż w swoim sklepie.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampanie produktowe <br /> <span>Product Listing Ads</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie PLA</a>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Kampanie <br /> dla e-commerce
                </h2>
                <p className="text-col__desc">
                  Google to bezkonkurencyjna i najpopularniejsza wyszukiwarka na
                  świecie, z której korzysta 97% polskich użytkowników. Dodajmy
                  do tego szybkie i przejrzyste efekty i nieograniczone pole do
                  optymalizacji kampanii. Właśnie to czyni Google Ads
                  największym i najbardziej skutecznym systemem reklamowym w
                  Internecie, a kampanie produktowe PLA podstawą wszystkich
                  naszych kampanii dla e-commerce.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/pla-offer-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-img">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3 text-col">
            <h2 className="text-col__title">
              Bądź tam, <br /> gdzie Twoi klienci
            </h2>
            <p className="text-col__desc">
              <span>Product Listing Ads</span> pokaże potencjalnym klientom
              Twojego sklepu internetowego produkt najbardziej dopasowany do ich
              wyszukiwania. Ta forma reklamy prezentuje zdjęcie, nazwę, cenę
              oferowanego produktu oraz nazwę sklepu internetowego lub adres
              jego strony www. Kliknięcie w reklamę spowoduje przejście
              użytkownika do podstrony konkretnego produktu.
            </p>
            <img
              src={require("../assets/images/pla-big-img-1.jpg")}
              alt=""
              className="text-col__image img-fluid"
            />
            <p className="text-col__image-desc">
              Przykład reklamy produktowej Product Listing Ads w wyszukiwarce
              Google
            </p>
            <p className="text-col__desc">
              Kampanie produktowe to, w większości przypadków, najbardziej
              rentowne kampanie dla sklepów internetowych. Uruchomienie reklamy
              nie wymaga wdrażania słów kluczowych, czy tekstów reklamowych i
              nie wyklucza jednoczesnego wyświetlania w reklamach tekstowych, co
              pozwala na znaczne zwiększenie widoczności na tle konkurencji.
              <br />
              <br />
              Dzięki reklamom PLA, przy stosunkowo niskich kosztach kliknięcia,
              możemy promować nawet kilkadziesiąt tysięcy produktów,
              jednocześnie cały czas zachowując ich aktualne atrybuty. Nasze
              sukcesy jednoznacznie wskazują, że jest to rozwiązanie idealne
              zarówno dla małych, jak i bardzo dużych sklepów e-commerce.
            </p>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/ecommerce-marketing-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">ROI Masters</h2>
                <p className="text-col__desc">
                  Nie będziemy tego ukrywać - mamy bzika na punkcie analityki.
                  Zdajemy sobie sprawę z tego, że ROI i ROAS to cel istnienia
                  każdego sklepu e-commerce oraz naszej agencji. Zwracamy uwagę
                  na marżowość Twoich produktów, sezonowość, a prowadzone
                  działania optymalizujemy pod Twój zysk - nie przychód. Nie
                  lubimy stać biernie, a optymalizacja to nasze drugie imię. To
                  właśnie z takim nastawieniem pomogliśmy już dziesiątkom firm
                  rozwinąć ich skrzydła inwestycyjne rozwijając budżety
                  marketingowe z kilku do kilkudziesięciu tysięcy złotych
                  miesięcznie i/lub otwierając je na ekspansję na nowe,
                  zagraniczne rynki. <br />A później… skalujemy!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text single-offer-section-text--pla-campaign">
        <div className="row">
          <div className="col-lg-8 offset-lg-3 text-col">
            <h2 className="text-col__title">
              <span>
                Kampanie <br /> produktowe to:{" "}
              </span>
              <br />
              <span id="shuffle">Szybkie efekty</span>
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-clients">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="section-header aligncenter">
              <span className="section-header__small-text">
                Wiemy, jak prowadzić skuteczne kampanie dla e-commerce
              </span>
              <h2 className="section-header__title">Zaufali nam</h2>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/thailand.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/gmr.jpg")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/komputronik.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/krakow.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/techland.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/greenpoint.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/steelseries.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/imperial-stawowa.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-case-study">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <h2 className="case-col__title">
              Sprawdź nasze przykładowe case studies produktów e-commerce
            </h2>
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/ecommerce-marketing-case-1.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">
                E-COMMERCE - BRANŻA TEKSTYLNA
              </p>
              <p className="post-wrapper__title">
                <span>771%</span> ROAS z Facebook Ads i <span>951%</span> ROAS z
                Google Ads w 6 miesięcy dla nowego sklepu e-commerce
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/nowy-sklep-ecommerce/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/ecommerce-marketing-case-2.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">
                sklep e-commerce - branża kosmetyczna
              </p>
              <p className="post-wrapper__title">
                Wzrost przychodów z 8813 zł do <span>389 372 zł</span>{" "}
                miesięcznie z kampanii produktowej Google Ads
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/perfumeria-euforia/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampanieProduktowePLAPage;
